import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';
/** @Account */
import Login from '@/views/Account/Login.vue';
import Signup from '@/views/Account/Signup.vue';
import SignupPassword from '@/views/Account/SignupPassword.vue';

/** @About */
import About from '@/views/About/index.vue';

/** @Kaigyou */
import Kaigyou from '@/views/Kaigyou/index.vue';

/** @Support */
import Shien from '@/views/Shien/index.vue';

/** @Sumu */
import Sumu from '@/views/Sumu/index.vue';

/** @Hataraku */
import Hataraku from '@/views/Hataraku/index.vue';

/** @Kosodate */
import Kosodate from '@/views/Kosodate/index.vue';

/** @Interview */
import Interview from '@/views/Interview/index.vue';
import InterviewDetail from '@/views/Interview/[open_id].vue';

/** @Event */
import Event from '@/views/Event/index.vue';
import EventDetail from '@/views/Event/[open_id].vue';

/** @Topics */
import Topics from '@/views/Topics/index.vue';
import TopicsDetail from '@/views/Topics/[slug].vue';

/** @Simulation */
import Simulation from '@/views/Simulation/index.vue';
import SimulationDetail from '@/views/Simulation/[id].vue';

/** @News */
import News from '@/views/News/index.vue';

/** @Pages */
import Contact from '@/views/Pages/Contact.vue';

/** @Error */
import NotFound from '@/views/Error/NotFound.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'top',
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    children: [
      {
        path: '/signup/password',
        name: 'signup/password',
        component: SignupPassword,
      },
    ],
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      name: 'about',
      title: '桐生市を知る',
      description: '桐生市の基本情報から、このまちで暮らすことの魅力、Instagramの投稿まで、桐生市をより知っていただくための情報をまとめました。',
      headColor: 'purple',
      showDetailHead: true,
      showMenu: true,
    },
  },
  {
    path: '/kaigyou',
    name: 'Kaigyou',
    component: Kaigyou,
    meta: {
      title: '店舗開業・起業のすすめ',
      name: 'kaigyou',
      description: '桐生市にはチェーン店が少ないと言われており、ここにしかない個性豊かな店舗がたくさんあります。店舗開業や起業、工房開設などに挑戦したい方に向けた各種支援制度や相談機関の情報を紹介します。',
      headColor: 'yellow',
      showDetailHead: true,
      showMenu: true,
    },
  },
  {
    path: '/kaigyou/topics',
    name: 'KaigyouTopics',
    component: Topics,
    meta: {
      title: 'お役立ちトピックス（店舗開業・起業のすすめ）',
      name: 'kaigyou-topics',
      description: '桐生市で「店舗開業・起業」のために役立つコンテンツをまとめ、随時更新しています。',
      headColor: 'yellow',
      showDetailHead: true,
      showMenu: false,
    },
  },
  {
    path: '/shien',
    name: 'Shien',
    component: Shien,
    meta: {
      title: '移住支援・サポート体制',
      name: 'shien',
      description: '多彩な顔ぶれの移住コーディネーターが、あなたの相談内容に応じた支援策の紹介と先輩移住者や専門機関につなげるなど、安心のサポートを行います。',
      headColor: 'green',
      showDetailHead: true,
      showMenu: true,
    },
  },
  {
    path: '/sumu',
    name: 'Sumu',
    component: Sumu,
    meta: {
      title: '住む',
      name: 'sumu',
      description: 'まちなかと自然が近く、両方の良いとこどりができる桐生市では、アクセスが良い駅近の物件から、緑に囲まれた一戸建ての物件まで、希望に合わせて選ぶことができます。ここでは物件探しや支援制度に関する情報をまとめています。',
      headColor: 'red',
      showDetailHead: true,
      showMenu: true,
    },
  },
  {
    path: '/sumu/topics',
    name: 'SumuTopics',
    component: Topics,
    meta: {
      title: 'お役立ちトピックス（住む）',
      name: 'sumu-topics',
      description: '桐生市で「住む」ために役立つコンテンツをまとめ、随時更新しています。',
      headColor: 'red',
      showDetailHead: true,
      showMenu: false,
    },
  },
  {
    path: '/hataraku',
    name: 'Hataraku',
    component: Hataraku,
    meta: {
      title: '働く',
      name: 'hataraku',
      description: '店舗開業・起業、テレワークから就農まで、スタイルに合わせた多様な働く場所や仕事探しに関する情報を紹介します。',
      headColor: 'yellowgreen',
      showDetailHead: true,
      showMenu: true,
    },
  },
  {
    path: '/hataraku/topics',
    name: 'HatarakuTopics',
    component: Topics,
    meta: {
      title: 'お役立ちトピックス（働く）',
      name: 'hataraku-topics',
      description: '桐生市で「働く」ために役立つコンテンツをまとめ、随時更新しています。',
      headColor: 'yellowgreen',
      showDetailHead: true,
      showMenu: false,
    },
  },
  {
    path: '/kosodate',
    name: 'Kosodate',
    component: Kosodate,
    meta: {
      title: '育てる',
      name: 'kosodate',
      description: '子育てに関する支援や、桐生ならではの教育プログラム、新たな学びの場からお出かけスポットまで、子育てに関する情報を紹介します。',
      headColor: 'blue',
      showDetailHead: true,
      showMenu: true,
    },
  },
  {
    path: '/kosodate/topics',
    name: 'KosodateTopics',
    component: Topics,
    meta: {
      title: 'お役立ちトピックス（育てる）',
      name: 'kosodate-topics',
      description: '桐生市で「育てる」ために役立つコンテンツをまとめ、随時更新しています。',
      headColor: 'blue',
      showDetailHead: true,
      showMenu: false,
    },
  },
  {
    path: '/topics',
    name: 'Topics',
    component: Topics,
    meta: {
      title: 'お役立ちトピックス',
      name: 'topics',
      description: '役立つコンテンツをまとめ、随時更新しています。',
      headColor: 'blue',
      showDetailHead: true,
      showMenu: false,
    },
  },
  {
    path: '/topics/:slug',
    name: 'TopicsDetail',
    component: TopicsDetail,
    meta: {
      title: 'お役立ちトピックス',
      name: 'topics',
      description: '役立つコンテンツをまとめ、随時更新しています。',
      headColor: 'blue',
      showDetailHead: true,
      showMenu: false,
    },
  },
  {
    path: '/interview',
    name: 'Interview',
    component: Interview,
    meta: {
      title: '移住者インタビュー',
      name: 'interview',
      description: 'IターンやUターン、店舗開業や独立、様々なきっかけや後押しを経て、桐生市に移住した方のリアルな声をお届けします。',
      headColor: 'pink',
      showDetailHead: true,
      showMenu: false,
    },
  },
  {
    path: '/interview/:open_id',
    name: 'InterviewDetail',
    component: InterviewDetail,
    meta: {
      title: '移住者インタビュー',
      name: 'interview',
      description: 'IターンやUターン、店舗開業や独立、様々なきっかけや後押しを経て、桐生市に移住した方のリアルな声をお届けします。',
      headColor: 'pink',
      showDetailHead: true,
      showMenu: false,
    },
  },
  {
    path: '/event',
    name: 'Event',
    component: Event,
    meta: {
      title: 'イベント情報',
      name: 'event',
      description: '桐生への移住や開業・起業に関するイベント情報をお届けします。',
      headColor: 'pink',
      showDetailHead: true,
      showMenu: false,
    },
  },
  {
    path: '/event/:open_id',
    name: 'EventDetail',
    component: EventDetail,
    meta: {
      title: 'イベント情報',
      name: 'event',
      description: '桐生への移住や開業・起業に関するイベント情報をお届けします。',
      headColor: 'pink',
      showDetailHead: true,
      showMenu: false,
    },
  },
  {
    path: '/simulation',
    name: 'Simulation',
    component: Simulation,
    meta: {
      title: '桐生暮らしシミュレーション',
      name: 'simulation',
      description: '自分がこれから桐生市に移住する場合、どんな支援が対象になるのか、また自分の好みや条件だと、どんな桐生での暮らし方ができるのか、シミュレーションしてみましょう。',
      headColor: 'green',
      showDetailHead: true,
      showMenu: false,
    },
  },
  {
    path: '/simulation/:id',
    name: 'SimulationDetail',
    component: SimulationDetail,
    meta: {
      title: '桐生暮らしシミュレーション',
      name: 'simulation',
      description: '自分がこれから桐生市に移住する場合、どんな支援が対象になるのか、また自分の好みや条件だと、どんな桐生での暮らし方ができるのか、シミュレーションしてみましょう。',
      showHeaderFromTop: true,
      isTransparentHeader: true,
    },
  },
  {
    path: '/news',
    name: 'News',
    component: News,
    meta: {
      title: 'お知らせ',
      name: 'news',
      description: 'むすびすむ桐生のお知らせ情報をお届けします。',
      headColor: 'blue',
      showDetailHead: true,
      showMenu: false,
    },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: {
      title: 'お問い合わせ',
      name: 'contact',
      description: '桐生市への移住に関するお問い合わせをWebフォームで受け付けています。\n下記、必要事項をご入力のうえ送信ください。\n移住コーディネーターが内容を確認後、ご連絡させていただきます。',
      headColor: 'blue',
      showDetailHead: true,
      showMenu: false,
    },
  },
  /** @Error */
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
    meta: {
      title: 'Not Found',
      description: 'お探しのページは見つかりませんでした',
      name: 'contact',
      headColor: 'blue',
      showDetailHead: true,
      showMenu: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

  // // ページ遷移時
  scrollBehavior(to, from, savedPosition) {
    let position = {};
    // fromとtoの違いがない場合はスルー
    if (from.path && to.path === from.path) return;
    if (!to.hash) {
      // ハッシュがなく、
      if (savedPosition) { // ブラウザバック
        position = { top: savedPosition.top };
      } else { // ページ遷移
        position = { top: 0 };
      }
    } else {
      // ハッシュが存在する場合スクロール
      position = { selector: to.hash };
    }
    return position;
  },
});

export default router;
