<template>
  <ul :class="s.list">
    <li
      v-for="(item, i) in simulations"
      :key="i">
      <component
        :is="item.disabled ? 'div' : 'router-link'"
        :to="`/simulation/${item.id}/`">
        <border-btn :bgImage="item.bannerImage" :imageType="isSpView ? 'fit' : 'prefix'" :class="item.disabled ? s.disabled : ''" :disabled="item.disabled">
          <div>
            <div :class="s.simulation_title">{{ item.detail.title }}</div>
            <div :class="s.simulation_start">シミュレーションする</div>
          </div>
          <div class="ms-3 me-auto" v-if="item.disabled">現在調整中...</div>
        </border-btn>
      </component>
    </li>
  </ul>
</template>

<script>
import { mapState } from 'vuex';
import BorderBtn from './BorderBtn.vue';

export default {
  name: 'simulation-banners',
  props: {
    name: {
      type: String,
    },
  },
  components: {
    BorderBtn,
  },
  data() {
    return {
      isSpView: false,
    };
  },
  created() {
    this.checkIsSpView();
  },
  computed: {
    ...mapState(['helper']),
    simulations() {
      return this.helper.master.simulation?.filter((sim) => sim.targetPage.includes(this.name));
    },
  },
  methods: {
    checkIsSpView() {
      this.isSpView = window.innerWidth <= 768;
    },
  },
};
</script>

<style lang="scss" module="s">
.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.list {
  > li {
    &:not(:first-child) {
      margin-top: 32px;
    }
  }
}

.simulation {
  &_title {
    font-size: 20px;
  }
  &_start {
    font-size: 14px;
  }
}

@include smView {
  .list {
    > li {
      &:not(:first-child) {
        margin-top: 24px;
      }
    }
  }
  .simulation {
    &_title {
      font-size: 20px;
      text-align: center;
    }
    &_start {
      font-size: 16px;
      text-align: center;
    }
  }
}
</style>
