<template>
  <footer :class="s.wrap">
    <div :class="s.left">
      <div>
        <div :class="s.links">
          <ul
            v-for="(items, k) in adjustedMenues"
            :key="k">
            <li v-for="item in items" :key="item.label">
              <router-link :to="item.path">{{ item.label }}</router-link>
            </li>
          </ul>
        </div>
        <div :class="s.instagram">
          <a href="https://www.instagram.com/musubisumu_kiryu/">
            <img src="/images/sns/instagram.png" alt="instagram-icon">
          </a>
        </div>
      </div>
      <div class="pc">
        <div :class="s.logo_wrap">
          <img src="/images/logo.png" alt="">
          <div :class="s.logo_text_wrap">
            <div :class="s.logo_text_sub">桐生市移住支援ポータルサイト</div>
            <div :class="s.logo_text">むすびすむ桐生</div>
          </div>
        </div>
        <!-- <div :class="s.waiting">「むすびすむ桐生」掲載画像募集中</div> -->
      </div>
    </div>
    <div :class="s.right">
      <div :class="s.contact_title">お問い合わせ</div>
      <ul :class="s.contact">
        <li
          v-for="c in contacts"
          :key="c.name">
          <div :class="s.contact_text">
            <div :class="s.contact_label">{{ c.label }}</div>
            <div :class="s.contact_tel">{{ c.tel }}<span v-if="c.tel_note">{{ c.tel_note }}</span></div>
            <div :class="s.contact_other" v-html="c.other"/>
            <div :class="s.contact_address" v-html="c.address"/>
            <div :class="s.contact_hours" v-html="c.hours"/>
            <div :class="s.contact_email">{{ c.email }}</div>
          </div>
          <iframe
            v-if="c.name === 'move'"
            :class="s.contact_map"
            src="//www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3210.9418613236458!2d139.3390005!3d36.410601899999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601ee246b92c0dab%3A0x6e0df46f281ef5!2z44Kz44Ov44O844Kt44Oz44Kw44O744Kz44Of44Ol44OL44OG44Kj44K544Oa44O844K544CMQ09DT1RPTU_jgI0!5e0!3m2!1sja!2sjp!4v1690767361195!5m2!1sja!2sjp" height="200" style="border: 2px solid rgba(0, 0, 0, 0.32); border-radius: 28px; margin-top: 32px; max-width: 400px; width: 100%;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          <div
            v-if="c.btn"
            :class="s.contact_btn">
            <basic-btn
              :tag="'a'"
              :link="c.btn.link"
              :size="'sm'"
              :width="'fit-content'">{{ c.btn.label }}</basic-btn>
          </div>
        </li>
      </ul>
    </div>
    <div class="sp">
      <div :class="s.logo_wrap">
        <img src="/images/logo.png" alt="">
        <div :class="s.logo_text_wrap">
          <div :class="s.logo_text_sub">桐生市移住支援ポータルサイト</div>
          <div :class="s.logo_text">むすびすむ桐生</div>
        </div>
      </div>
      <div :class="s.waiting">「むすびすむ桐生」掲載画像募集中</div>
    </div>
  </footer>
</template>

<script>
import {
  BasicBtn,
} from '@/components/parts';

export default {
  name: 'global-footer',
  components: { BasicBtn },
  data() {
    return {
      items: [
        {
          label: '桐生市を知る',
          path: '/about/',
        },
        {
          label: '移住支援・サポート体制',
          path: '/shien/',
        },
        {
          label: '店舗開業・起業のすすめ',
          path: '/kaigyou/',
        },
        {
          label: 'インタビュー',
          path: '/interview/',
        },
        {
          label: 'イベント',
          path: '/event/',
        },
        {
          label: '働く',
          path: '/hataraku/',
        },
        {
          label: '住む',
          path: '/sumu/',
        },
        {
          label: '育てる',
          path: '/kosodate/',
        },
      ],
      contacts: [
        {
          name: 'move',
          label: '移住に関する相談・お問い合わせ',
          tel: '050-3529-6573',
          other: '桐生市移住支援フロント むすびすむ桐生',
          address: '〒376-0031 群馬県桐生市本町５丁目51番地 東武桐生ビル１階cocotomo内',
          hours: '平日・土日祝日 10:00〜17:00　休業日：年末年始',
          email: 'musubisumu@kiryu-iju.jp',
          btn: {
            link: '/contact/',
            label: 'お問い合わせフォーム',
          },
        },
        {
          name: 'site',
          label: 'このサイトに関するお問い合わせ',
          tel: '0277-32-3812',
          other: '桐生市企画課移住定住推進室',
          address: '〒376-8501 群馬県桐生市織姫町１−１',
          hours: '平日 8:30〜17:15　休業日：土日祝日・年末年始',
          email: 'ijuteiju@city.kiryu.lg.jp',
        },
      ],
    };
  },
  computed: {
    adjustedMenues() {
      const menues = {
        left: [],
        right: [],
      };
      this.items.forEach((item, i) => {
        if (this.items.length / 2 >= i) menues.left.push(item);
        else menues.right.push(item);
      });
      return menues;
    },
  },
};
</script>

<style lang="scss" module="s">
.wrap {
  background-color: #ffffff;
  padding: 80px 120px;
  display: flex;
  align-items: stretch;
  height: var(--footer-height);
}

.left, .right {
  width: 50%;
}

.left {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.right {
  border-left: 1px solid var(--border-divider-gray);
  padding-left: 80px;
}

.links {
  display: flex;
  > * {
    width: 50%;
    > li {
      &:not(:first-child) {
        margin-top: 32px;
      }
    }
    a {
      font-weight: 700;
      color: var(--label-primary);
    }
  }
}

.instagram {
  margin-top: 32px;
  width: 20px;
  height: 20px;
  > img {
    width: 100%;
  }
}

.logo {
  &_wrap {
    display: flex;
    align-items: center;
    margin-top: 50px;
    img {
      width: 50px;
    }
  }
  &_text {
    &_wrap {
      margin-left: 12px;
      letter-spacing: 0.18em;
      line-height: 1;
      font-family: 'Zen Kaku Gothic New';
    }
    &_sub {
      font-size: 12px;
      margin-bottom: 6px;
    }
    font-size: 24px;
  }
}

.contact {
  margin-top: 32px;

  > li {
    &:not(:first-child) {
      margin-top: 32px;
    }
  }
  &_text {
    opacity: .8;
  }
  &_title {
    font-size: 24px;
    opacity: .8;
  }
  &_label {
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 8px;
  }
  &_tel {
    font-weight: 700;
    font-size: 20px;
    line-height: 1;
    margin-bottom: 8px;

    span {
      font-size: 16px;
      font-weight: normal;
      margin-left: 8px;
    }
  }
  &_other, &_address, &_hours {
    font-size: 12px;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
  &_email {
    font-size: 12px;
  }
  &_map {
    margin-top: 16px !important;
  }
  &_btn {
    margin-top: 12px;
  }
}

.waiting {
  font-size: 12px;
  margin-top: 20px;
  text-decoration: underline;
}

@include smView {
  .wrap {
    display: block;
    padding: 20px;
    height: var(--footer-height-sm);
    > * {
      width: 100%;
    }
  }

  .right {
    border-left: none;
    border-top: 1px solid ;
    margin-top: 48px;
    padding-top: 48px;
    padding-left: 0;
  }

  .links {
    display: block;
    > * {
      width: 100%;
      margin-right: 0;
      &:not(:first-child) {
        margin-top: 32px;
      }
    }
  }

  .logo_wrap {
    justify-content: center;
  }

  .waiting {
    text-align: center;
  }
}
</style>
