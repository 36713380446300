<template>
  <transition name="fade">
    <header
      :class="[s.header, headerBg]"
      v-if="layout.header.isShow">
      <router-link to="/" :class="s.logo">
        <img
          src="/images/logo.png" alt=""
          :class="s.img">
        <div :class="s.text">
          <p :class="s.sub">桐生市移住支援ポータルサイト</p>
          <p :class="s.musubisumu">むすびすむ桐生</p>
        </div>
      </router-link>
    </header>
  </transition>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'global-header',
  data() {
    return {
      menuItems: [
        {
          label: 'TOP',
          path: '/',
        },
      ],
    };
  },
  computed: {
    ...mapState(['layout']),
    headerBg() {
      if (this.$route.name === 'SimulationDetail') return this.s.blur;
      return this.s[this.layout.header.color];
    },
  },
};
</script>

<style lang="scss" module="s">
.header {
  transition: all .3s;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 101;
  display: flex;
  align-items: center;
  height: var(--header-height);

  &.transparent {
    background-color: transparent;
  }
  &.blur {
    background-color: transparent;
    backdrop-filter: blur(12px);
  }

  &.semi-transparent-white {
    background-color: rgba(#ffffff, .9);
  }

  &.solid-white {
    background-color: #ffffff;
  }
}

.logo {
  display: flex;
  align-items: center;
  color: var(--brand-gray);
  padding-left: 24px;
  font-family: 'Zen Kaku Gothic New';
  .img {
    margin-right: 20px;
    width: 72px;
  }
  .text {
    .sub {
      font-size: 13px;
      line-height: 13px;
      margin-bottom: 5px;
      letter-spacing: .18em;
    }
    .musubisumu {
      font-size: 28px;
      line-height: 28px;
      letter-spacing: .18em;
    }

    @include smView {
      display: none;
    }
  }
}
</style>
