<template>
  <div>
    <SectionContainer id="charm">
      <SectionFrame
        :title="'桐生で働く魅力'"
        :descriptionSize="'lg'"
        :maxWidth="1040"
        :marginTop="112"
        :smMarginTop="80">
        <FlexList
          :spaceX="40"
          :spaceY="32"
          :count="4"
          :smCount="1"
          :align="'center'"
          :wrap="true">
          <li v-for="(item, i) in charms" :key="i">
            <ContentCard
              :imgBorderRadius="28"
              :img="item.media"
              :title="item.label"
              :content="item.content"
              :contentSidePadding="0"
              :fontSize="12">
            </ContentCard>
          </li>
        </FlexList>
        <Spacer :y="4"/>
        <FlexList :align="'center'">
          <li><basic-btn :tag="'a'" :link="'/contact/'">移住コーディネーターに問い合わせてみる</basic-btn></li>
        </FlexList>
      </SectionFrame>
    </SectionContainer>

    <SectionContainer id="topics">
      <SectionFrame
        :title="'お役立ちトピックス'"
        :description="'桐生市で「働く」ために役立つコンテンツをまとめ、随時更新しています。'"
        :descriptionSize="'lg'">
        <Bg :bgcolor="'gray'" :radius="28">
          <TopicList
            type="hataraku"
            :limit="5" />
          <FlexList :align="'center'">
            <div>
              <basic-btn
                :tag="'a'"
                :link="'/hataraku/topics/'">もっと見る</basic-btn>
            </div>
          </FlexList>
          <spacer :y="4"/>
        </Bg>
      </SectionFrame>
    </SectionContainer>

    <SectionContainer id="simulation">
      <SectionFrame
        :title="'桐生暮らしシミュレーション'"
        :description="'自分がこれから桐生市に移住する場合、どんな支援が対象になるのか、また自分の好みや条件だと、どんな桐生での暮らし方ができるのか、シミュレーションしてみましょう。'"
        :descriptionSize="'lg'">
        <SimulationBanners name="hataraku"/>
      </SectionFrame>
    </SectionContainer>

    <SectionContainer id="find-work">
      <SectionFrame
        :title="'多様な働き方'"
        :description="'あなたのスタイルに合った働く場所で夢を実現しましょう'"
        :descriptionSize="'lg'">
        <SimpleList
          :hasLine="false"
          :margin="24"
          v-if="card.cards?.length">
          <BgListItem
            v-for="item in adjustCards(card.cards, 'works')"
            :key="item"
            :bgcolor="'gray'">
              <ContentListFrame :item="item" />
          </BgListItem>
        </SimpleList>
      </SectionFrame>
    </SectionContainer>

    <SectionContainer id="support">
      <SectionFrame
        :title="'働くに関する支援制度'"
        :description="'移住し、働くための支援メニューを紹介します。'"
        :descriptionSize="'lg'">
        <SimpleList
          :hasLine="false"
          :margin="24"
          v-if="card.cards?.length">
          <BgListItem
            v-for="item in adjustCards(card.cards, 'supports')"
            :key="item"
            :bgcolor="'gray'">
              <ContentListFrame :item="item" />
          </BgListItem>
        </SimpleList>
      </SectionFrame>
    </SectionContainer>

    <spacer :y="20" :smY="15"/>
    <Interview
      id="interview"
      :category="categoryId" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  Spacer,
  SectionFrame,
  BasicBtn,
  ContentListFrame,
  Bg,
  SectionContainer,
  SimpleList,
  FlexList,
  ContentCard,
  Interview,
  TopicList,
  BgListItem,
  SimulationBanners,
} from '@/components/parts';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'support-living',
  components: {
    TopicList,
    BgListItem,
    Spacer,
    SectionFrame,
    BasicBtn,
    ContentListFrame,
    Bg,
    SectionContainer,
    SimpleList,
    FlexList,
    ContentCard,
    Interview,
    SimulationBanners,
  },
  mixins: [cf],
  data() {
    return {
      charms: [
        {
          label: '多様な働き方の実践者が多い',
          content: '店舗開業・起業や就農からテレワークまで、自分に合った働き方をしている移住者が多く、気軽に話を聞くことができます。',
          media: '/images/detail/working_charm_1.jpg',
        },
        {
          label: '支援機関に相談できる',
          content: '店舗開業・起業はもちろん、就農においても「挑戦」を応援し、相談できる支援機関・専門家がいます。',
          media: '/images/detail/working_charm_2.jpg',
        },
        {
          label: 'テレワーク環境も充実',
          content: 'まちなかの中心市街地エリアと自然豊かな黒保根エリアの、それぞれにきれいで快適なテレワーク環境があります。',
          media: '/images/detail/working_charm_3.jpg',
        },
      ],
      card: {
        loading: true,
        notFound: false,
        cards: [],
      },
    };
  },
  created() {
    this.getCards();
  },
  computed: {
    ...mapState(['helper']),
    type() {
      return this.$route.meta.name || null;
    },
    categoryId() {
      return this.helper?.categories?.find((d) => d.name === this.type)?.id || null;
    },
  },
  methods: {
    getCards() {
      const params = {
        flags: [1],
        type: this.type,
        orderBy: 'order',
        order: 'asc',
      };

      this.axios({
        method: 'GET',
        url: '/v1/card/get/list',
        params,
      })
        .then((response) => {
          this.card.cards = response.data.cards.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
          this.card.notFound = true;
        })
        .finally(() => {
          this.card.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" module="s">
</style>
