<template>
  <div
    id="menu-trigger"
    :class="[
      s.wrap,
      flag.started && !flag.completed ? s.h_100 : '',
    ]">
    <!-- 無効URL -->
    <div v-if="!qaObj"></div>
    <!-- 開始前 -->
    <div v-else-if="!flag.started" :class="s.top">
      <div :class="s.top_title">桐生暮らし<br v-if="['xs', 'sm'].includes(layout.displaySize)">シミュレーション</div>
      <div :class="s.top_description">桐生市に移住すると対象になり得る支援をシミュレーションしてみよう。</div>
      <div :class="s.top_subject">
        <div v-if="subjectDetail.subtitle" :class="s.top_subject_subtitle">{{ subjectDetail.subtitle }}</div>
        <div v-if="subjectDetail.title" :class="s.top_subject_title">{{ subjectDetail.title }}</div>
        <div v-if="subjectDetail.description" :class="s.top_subject_description">{{ subjectDetail.description }}</div>
      </div>
      <div v-if="qaObj.target" :class="s.top_target">
        <div
          :class="s.top_target_value"
          v-html="qaObj.target"/>
      </div>
      <!-- <ul v-if="qaObj.checks?.length" :class="s.top_checks">
        <li
          v-for="(check, i) in qaObj.checks"
          :key="i">
          <div :class="s.top_checks_num">チェック{{ i + 1 }}</div>
          <div :class="s.top_checks_question">{{ check.question }}</div>
          <ul
            v-if="check.answers?.length"
            :class="s.top_checks_answers">
            <li
              v-for="(answer, i) in check.answers"
              :key="i">
              <i class="fa-solid fa-check"/>
              <div>
                <div :class="s.top_checks_answers_title">
                  <div>{{ answer.title }}</div>
                </div>
                <div :class="s.top_checks_answers_description">{{ answer.description }}</div>
              </div>
            </li>
          </ul>
        </li>
      </ul> -->
      <div :class="s.top_start">
        <basic-btn
          color="wht"
          width="full"
          :class="s.basic_btn"
          :disabled="qaObj.disabled"
          @click="!qaObj.disabled ? start() : ''">{{ qaObj.disabled ? '現在調整中...' : 'スタート' }}</basic-btn>
      </div>
      <div :class="s.others">
        <div :class="s.others_title">そのほかのシミュレーション</div>
        <div
          :class="s.others_list"
          v-for="(item, i) in helper.master.simulation?.filter((sim) => sim.id !== id)"
          :key="i">
          <router-link
            :to="`/simulation/${item.id}/`">
            <div>
              <div :class="s.simulation_title">{{ item.detail.title }}</div>
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <!-- 回答中 -->
    <div v-else :class="s.simulation_wrap">
      <div :class="s.detail">
        <div :class="s.detail_simulation">桐生暮らしシミュレーション</div>
        <div :class="s.detail_title">{{ subjectDetail.title }}</div>
      </div>
      <div
        v-if="!flag.completed"
        :class="s.question">
        <div :class="s.question_q">Q</div>
        <div :class="s.question_label" v-html="question.label"></div>
        <div :class="s.question_note" class="mt-2"><div v-html="question.note"/></div>
        <ul  :class="s.question_answers">
          <li
            v-for="answer in question.answers"
            :key="answer.value">
            <border-btn
              :hasIcon="false"
              align="center"
              @click="next(answer, question.id)">{{ answer.label }}</border-btn>
          </li>
        </ul>
        <button
          v-if="questionHistoryIds.length"
          :class="s.question_back"
          type="button"
          @click="back()">
          <i class="fa-solid fa-angle-left"/>
          <div>戻る</div>
        </button>
      </div>

      <!-- 結果 -->
      <div v-else :class="s.result">
        <div :class="s.result_label">結果</div>
        <div>
          <!-- 対象 -->
          <div v-if="resultDetails.length" :class="s.result_detail">
            <div v-if="qaObj.type === 1" :class="s.result_text" v-html="qaObj.resultTitle"/>
            <div v-if="qaObj.type === 2 && resultType === 'get'" :class="s.result_note">以下の支援の対象となると見込まれます。<br>詳細は以下の担当にお問合せください。</div>
            <div v-if="qaObj.type === 2 && resultType === 'use'" :class="s.result_note">以下の施設をご利用いただけます。</div>
            <ul :class="s.result_list">
              <li
                v-for="(result, i) in resultDetails"
                :key="i">
                <div v-if="result.prefixLabel" :class="s.prefixLabel">{{ result.prefixLabel }}</div>
                <component
                  :is="result.link ? 'a' : 'div'"
                  :href="result.link || ''"
                  :target="'_blank' || ''"
                  :class="s.result_detail_label">
                  {{ result.label }}
                  <i v-if="result.link" class="fa-solid fa-arrow-up-right-from-square ms-2"/>
                </component>
                <div :class="s.result_detail_items">
                  <div v-if="result.department">{{ result.department }}</div>
                  <div v-if="result.tel">{{ result.tel }}</div>
                  <div v-if="result.naisen">（内線 {{ result.naisen }}）</div>
                  <ul
                    v-if="result.contact?.length"
                    :class="s.result_detail_contact">
                    <li
                      v-for="(contact, i) in result.contact"
                      :key="i">
                      <div>{{ contact.label }}</div>
                      <div v-if="contact.tel">（{{ contact.tel }}）</div>
                      <div v-if="contact.naisen">（内線 {{ contact.naisen }}）</div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <!-- 対象外 -->
          <div v-else :class="s.result_detail">
            <div :class="s.result_detail_text">{{ qaObj.notApplicable?.label }}</div>
            <div :class="s.result_detail_description" v-if="qaObj.notApplicable?.description">{{ qaObj.notApplicable.description }}</div>
            <div v-if="qaObj.notApplicable?.contact" class="mt-4">
              <div v-if="qaObj.notApplicable.contact.label">{{ qaObj.notApplicable.contact.label }}</div>
              <div v-if="qaObj.notApplicable.contact.tel">{{ qaObj.notApplicable.contact.tel }}</div>
              <div v-if="qaObj.notApplicable.contact.naisen">（内線 {{ qaObj.notApplicable.contact.naisen }}）</div>
            </div>
          </div>
        </div>
        <!-- 回答した内容 -->
        <div :class="s.selected">
          <div :class="s.selected_label">選択した項目</div>
          <ul :class="s.selected_list">
            <li
              v-for="(selected, i) in adjustedAnswers"
              :key="i">
              <div v-html="selected.label"></div>
              <div :class="s.selected_answer">{{ selected.value }}</div>
            </li>
          </ul>
        </div>
        <basic-btn
          :class="[s.to_top, s.basic_btn]"
          color="wht"
          width="full"
          @click="toTop()">シミュレーショントップへ</basic-btn>
        <basic-btn
          :class="[s.to_list, s.basic_btn]"
          tag="a"
          link="/simulation/"
          color="wht"
          width="full">シミュレーション一覧へ</basic-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  BasicBtn,
  BorderBtn,
} from '@/components/parts';

export default {
  name: 'simulation-type',
  components: {
    BasicBtn,
    BorderBtn,
  },
  data() {
    return {
      flag: {
        started: false,
        completed: false,
      },
      id: null,
      qaObj: null,
      questionHistoryIds: [],
      questionId: null,
      answers: {},
      resluts: [],
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapState(['helper', 'layout']),
    simulationData() {
      return this.helper.master.simulation;
    },
    subjectDetail() {
      return this.qaObj?.detail;
    },
    question() {
      if (!this.qaObj) return null;
      return this.qaObj.questions.find((q) => q.id === this.questionId);
    },
    resultDetails() {
      return this.qaObj.results.filter((result) => this.results.includes(result.value)) || [];
    },
    resultType() {
      if (this.resultDetails.some((rst) => rst.type === 'place')) return 'use';
      return 'get';
    },
    adjustedAnswers() {
      const answers = [];
      Object.keys(this.answers).forEach((key) => {
        if (this.answers[key]) {
          const question = this.qaObj.questions.find((q) => Number(key) === q.id);
          const answerObj = question.answers.find((a) => a.value === this.answers[key]);
          answers.push({
            label: question.label,
            value: answerObj.label,
          });
        }
      });
      return answers;
    },
  },
  watch: {
    $route: {
      handler() {
        this.init();
      },
    },
    simulationData: {
      handler() {
        if (this.simulationData) this.init();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    scroll2Top() {
      this.$nextTick(() => {
        const scrollY = window.scrollY;
        scrollTo(0, -scrollY);
      });
    },

    toTop() {
      this.init();
      this.flag.started = false;
    },

    init() {
      this.id = this.$route.params.id;
      if (!this.simulationData) return;
      this.flag.completed = false;
      this.qaObj = null;
      this.questionId = null;
      this.answers = {};
      this.result = null;
      this.supports = null;
      this.questionHistoryIds = [];
      this.scroll2Top();

      this.qaObj = this.simulationData.find((data) => data.id === this.id);
      if (this.qaObj) {
        this.questionId = this.qaObj.start_id;
        this.qaObj.questions.forEach((q) => {
          this.answers[q.id] = null;
        });
      }
    },

    start() {
      this.init();
      this.flag.started = true;
    },

    next(answer, questionId) {
      this.questionHistoryIds.push(questionId);
      this.answers[questionId] = answer.value;
      if (answer.next.type === 'question') this.questionId = answer.next.id;
      else if (answer.next.type === 'completed') {
        this.flag.completed = true;
        this.results = answer.next.results || [];
      }
      this.scroll2Top();
    },

    back() {
      const beforeQuestionId = this.questionHistoryIds.slice(-1)[0];
      this.answers[beforeQuestionId] = null;
      this.questionId = beforeQuestionId;
      this.questionHistoryIds.pop();
    },
  },
};
</script>

<style lang="scss" module="s">
.wrap {
  background: var(--bg-green);
  width: 100vw;
  min-height: 100vh;
  padding: 132px 32px 240px;
  > * {
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    width: 100%;
  }

  &.h_100 {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.top {
  text-align: center;
  &_title {
    font-size: 40px;
    font-weight: bold;
  }
  &_description {
    font-size: 20px;
    margin-top: 16px;
    margin-bottom: 32px;
    line-height: 1.6;
  }
  &_subject {
    padding: 24px 32px;
    border-radius: 28px;
    width: 100%;
    background-color: #ffffff;
    border: 2px solid var(--label-primary);
    &_subtitle {
      font-size: 16px;
      margin-bottom: 8px;
    }
    &_title {
      font-size: 24px;
      font-weight: bold;
    }
    &_description {
      font-size: 16px;
      margin-top: 16px;
      color: var(--label-secondary);
    }
  }

  &_target {
    margin-top: 42px;
    text-align: start;
    &_title {
      font-weight: bold;
    }
    &_value {
      margin-top: 4px;
    }
  }

  &_checks {
    margin-top: 32px;
    > li {
      &:not(:first-child) {
        margin-top: 32px;
      }
    }
    &_num {
      padding: 4px 12px;
      border-radius: 14px;
      background-color: rgba(#000000, .06);
      width: fit-content;
      font-weight: bold;
    }
    &_question {
      font-weight: bold;
      margin-top: 16px;
    }
    &_answers {
      margin-top: 16px;
      > li {
        display: flex;
        &:not(:first-child) {
          margin-top: 16px;
        }
        > i {
          font-size: 20px;
          margin-right: 12px;
        }
      }


      &_title {
        font-weight: bold;
        line-height: 1;
      }

      &_description {
        margin-top: 8px;
      }
    }
  }

  &_start {
    margin-top: 48px;
  }
}

.others {
  margin-top: 56px;
  &_title {
    font-weight: bold;
  }
  &_list {
    &:not(:first-child) {
      margin-top: 12px;
    }
    > a {
      max-width: 350px;
      width: 100%;
      display: inline-block;
      padding: 8px 32px;
      border: 1px solid var(--label-primary);
      color: var(--label-primary);
      border-radius: 50px;
      transition: all .3s;
      &:hover {
        opacity: .5;
      }
    }
  }
}

.simulation_wrap {
  max-width: 480px;
}

.detail {
  position: absolute;
  top: calc(var(--header-height) + 32px);
  left: 32px;
  border-left: 3px solid var(--brand-gray);
  color: var(--brand-gray);
  padding-left: 12px;
  &_simulation {
    font-size: 24px;
    font-weight: bold;
  }
  &_title {
    font-size: 20px;
  }
}

.question {
  margin: 70px auto;
  &_q {
    font-size: 32px;
    font-weight: bold;
  }
  &_label {
    font-size: 24px;
    font-weight: bold;
    margin-top: 8px;
  }
  &_answers {
    margin-top: 35px;
    > li {
      &:not(:first-child) {
        margin-top: 20px;
      }
    }
  }
  &_back {
    margin-top: 32px;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    font-weight: bold;
    > i {
      font-size: 14px;
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      width: 24px;
      border: 2px solid var(--label-primary);
      border-radius: 24px;
      background-color: #ffffff;
      transition: all .3s;
    }

    &:hover {
      > i {
        color: #ffffff;
        background-color: var(--label-primary);
      }
    }
  }
}

.result {
  max-width: 480px;
  padding-top: 120px;
  .prefixLabel {
    font-size: 14px;
    padding: 2px 6px;
    background-color: rgba(#000000, .06);
    margin-bottom: 4px;
    width: fit-content;
    white-space: nowrap;
  }
  &_label {
    padding: 6px 16px;
    background-color: var(--label-primary);
    color: #ffffff;
    font-weight: bold;
    border-radius: 14px;
    display: inline-block;
  }
  &_detail {
    margin-top: 24px;
    &_text {
      font-weight: bold;
    }
    &_label {
      font-weight: bold;
      font-size: 20px;
      text-decoration: underline;
      color: var(--label-primary);
    }
    &_description {
      margin-top: 16px;
    }
    &_items {
      white-space: nowrap;
    }
    &_contact {
      > li {
        display: flex;
        > * {
          &:not(:first-child) {
            margin-left: 4px;
          }
        }
      }
    }
  }
  &_list {
    margin-top: 24px;
    > li {
      &:not(:first-child) {
        margin-top: 24px;
      }
    }
  }
  &_not_applicable {
    font-weight: bold;
  }
}

.selected {
  margin-top: 64px;
  &_label {
    padding: 4px 12px;
    font-weight: bold;
    border-radius: 14px;
    background-color: rgba(#000000, .06);
    width: fit-content;
  }
  &_list {
    margin-top: 24px;
    > li {
      &:not(:first-child) {
        margin-top: 16px;
        padding-top: 16px;
        border-top: 1px solid var(--border-primary);
      }
    }
  }
  &_answer {
    font-weight: bold;
    text-align: right;
  }
}

.to_top {
  margin-top: 48px;
}
.to_list {
  margin-top: 24px;
}

.basic_btn {
  padding: 16px 24px !important;
  &:hover {
    background-color: var(--label-primary) !important;
    color: #ffffff !important;
    border-color: var(--label-primary) !important;
    opacity: 1;
  }
}
.btn {
  background-color: #ffffff;
  border: 2px solid var(--label-primary);
  font-weight: bold;
  padding: 20px 32px;
  border-radius: 28px;
  width: 100%;
  transition: all .3s;
  &:hover {
    background-color: var(--label-primary);
    color: #ffffff;
  }
}


@include smView {
  .wrap {
    &.h_100 {
      display: block;
    }
  }
  .top {
    &_title {
      font-size: 32px;
    }
    &_description {
      font-size: 14px;
    }
    &_subject {
      text-align: start;
      &_title {
        font-size: 20px;
      }
      &_description {
        font-size: 14px;
      }
    }
  }

  .detail {
    position: relative;
    top: 0; left: 0;
    &_simulation {
      font-size: 20px;
    }
    &_title {
      font-size: 16px;
    }
  }

  .question {
    margin: 0 auto;
    padding-top: 64px;
  }

  .result {
    padding-top: 64px;
  }
}
</style>
