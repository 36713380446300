<template>
  <div>
    <Loader :flag="!flag.loaded">
      <SectionContainer>
        <SectionFrame
          :maxWidth="1080"
          :marginTop="50"
          :smMarginTop="50">
          <ul :class="s.tag_list" v-if="adjustedTags.length">
            <li
              v-for="(tagGroup,i) in adjustedTags"
              :key="i">
              <div :class="s.tag_group_name">{{ tagGroup.name }}</div>
              <div
                :class="s.tag_group_link"
                v-if="tagGroup.name === 'エリア'">
                桐生市内の各エリア紹介は<router-link to="/about/">こちら</router-link>
              </div>
              <TagList v-if="adjustedTags.length" ref="tagList">
                <li v-for="tag of tagGroup.list" :key="tag.id">
                  <tag-btn
                    :isActive="adjustSelectedTags.includes(tag.id)"
                    :shape="'straight'"
                    :type="'check'"
                    @click="clickSearchTags(tag)">{{ tag.label }}</tag-btn>
                </li>
              </TagList>
            </li>
          </ul>
          <Spacer :y="6"/>
          <FlexList
            :spaceX="24"
            :spaceY="24"
            :count="3"
            :smCount="1"
            :wrap="true"
            v-if="lists?.length">
            <li v-for="item in lists" :key="item.id">
              <router-link :to="`/interview/${item.open_id}/`" :class="s.itemLink">
                <ContentCard
                  :img="item.medias[0]?.url"
                  :imgRatio="70"
                  :smImgRatio="70"
                  :date="formatTimestamp(item.interview_date, 'YYYY.MM.DD')"
                  :name="item.title"
                  :content="item.summary"
                  :contentBg="'wht'"
                  :radius="28"
                  :hasShadow="true">
                  <spacer :y="1"/>
                  <FlexList
                    :spaceX="12"
                    :spaceY="12"
                    :wrap="true">
                    <router-link
                      :to="`/interview/?tag=${tag.id}`"
                      v-for="tag in item.tags" :key="tag.id">
                      <tag-btn
                        :size="'sm'"
                        :type="'category'">{{ tag.label }}</tag-btn>
                    </router-link>
                  </FlexList>
                </ContentCard>
              </router-link>
            </li>
          </FlexList>
          <div v-else-if="!flag.error && flag.loaded" :class="s.error"><span>該当の移住者インタビューがありませんでした</span></div>
          <div v-else-if="flag.loaded" :class="s.error"><span>移住者インタビューの読み込みに失敗しました</span></div>
        </SectionFrame>
      </SectionContainer>
      <spacer :y="20"/>
    </Loader>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  SectionContainer,
  SectionFrame,
  FlexList,
  TagBtn,
  TagList,
  ContentCard,
  Spacer,
  Loader,
} from '@/components/parts';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'interview',
  mixins: [cf],
  components: {
    SectionContainer,
    SectionFrame,
    FlexList,
    TagBtn,
    TagList,
    ContentCard,
    Spacer,
    Loader,
  },
  data() {
    return {
      flag: {
        loaded: false,
        error: false,
      },
      lists: [],
      tags: [],
      selectedTags: {},
    };
  },
  created() {
    this.getTags();
  },
  computed: {
    ...mapState(['helper']),
    adjustSelectedTags() {
      return Object.values(this.selectedTags).flat();
    },
    queryTagId() {
      return this.$route.query?.tag || null;
    },
    adjustedTags() {
      const tags = this.helper.master.tag?.types.slice(0, 2) || [];
      tags.forEach((tagType) => {
        tagType.list = this.tags.filter((tag) => tag.type === tagType.id);
      });
      return tags;
    },
  },
  watch: {
    selectedTags: {
      handler() {
        this.flag.loaded = false;
        this.getList();
      },
      deep: true, // オブジェクトの中身まで監視
    },
    $route: {
      handler() {
        if (this.queryTagId) {
          this.flag.loaded = false;
          const tag = this.tags.find((t) => t.id === Number(this.queryTagId));
          this.selectedTags = {};
          this.selectedTags[tag.type] = [tag.id];
        }
      },
      deep: true,
    },
  },
  methods: {
    searchQueryTag() {
      const query = this.$route.query;
      if (query?.tag) {
        const tag = this.tags.find((t) => t.id === Number(query.tag));
        if (tag) {
          this.selectedTags[tag.type] = [tag.id];
        }
      }
    },
    getList() {
      const params = {
        flags: [1],
        orderBy: 'open_id',
        order: 'desc',
        tags: Object.values(this.selectedTags),
      };
      this.axios({
        method: 'GET',
        url: '/v1/interview/get/list/',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.lists = res.interviews.data;
          this.flag.loaded = true;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
          this.flag.error = true;
          this.flag.loaded = true;
        });
    },
    getTags() {
      const params = {
        isUsedInInterview: 1,
      };
      this.axios({
        type: 'GET',
        url: '/v1/tag/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.tags = res.tags.data;
          if (this.queryTagId) this.searchQueryTag();
          this.getList();
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        });
    },
    groupTagsByType(tags) {
      return Object.values(tags.reduce((acc, tag) => {
        if (!acc[tag.type]) acc[tag.type] = [];
        acc[tag.type].push(tag);
        return acc;
      }, {}));
    },
    clickSearchTags(tag) {
      const tagType = tag.type;
      if (this.selectedTags[tagType] && Array.isArray(this.selectedTags[tagType])) {
        if (this.selectedTags[tagType].includes(tag.id)) {
          this.selectedTags[tagType].splice(this.selectedTags[tagType].indexOf(tag.id), 1);
        } else {
          this.selectedTags[tagType].push(tag.id);
        }
      } else {
        this.selectedTags[tagType] = [tag.id];
      }

      if (!this.selectedTags[tagType].length) {
        delete this.selectedTags[tagType];
      }
    },
  },
};
</script>

<style lang="scss" module="s">
.shadow {
  box-shadow: var(--surface-shadow);
}
.itemLink {
  display: block;
  height: 100%;
  > * {
    height: 100%;
  }
}

.tag_list {
  width: fit-content;
  margin: 0 auto;
  > li {
    width: fit-content;
    min-width: 100%;
    &:not(:first-child) {
      margin-top: 12px;
    }
  }
}
.tag_group_name {
  padding: 4px 12px;
  font-size: 14px;
  margin-bottom: 12px;
  color: var(--label-secondary);
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.05);
  mix-blend-mode: multiply;
}
.tag_group_link {
  font-size: 14px;
  margin-bottom: 12px;
  > a {
    color: var(--label-primary);
    text-decoration: underline;
  }
}

.error {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  > * {
    padding: 8px 32px;
    display: inline-block;
    margin: 0 auto;
    background-color: #FFFFFF;
    border-radius: 8px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    opacity: .8;
  }
}
</style>
